<template>
  <div style="background: #f5f5f5;color: #000;height: 100%;">
    <van-nav-bar title="我的预约" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <van-icon
          name="plus"
          class="ddccd"
          style="vertical-align: middle;color: #55bae2 !important;"
        />
        <span @click.stop="toyuyue" class="liusui" style="vertical-align: middle">线下预约</span>
      </template>
    </van-nav-bar>
    <div style="background: #f5f5f5;" class="hedsding">
      <!-- <div class="mian" v-for="(item,idx) in list" :key="idx">
        <div class="dian">
          <span class="left">{{item.garageName}}</span>
          <span class="right">￥{{item.amountReceivable?item.amountReceivable.toFixed(2):''}}</span>
        </div>

        <div class="xianmu">
          <div class="xianmu_A">
            <span>车牌：</span>
            <span class="chepaih">{{item.carNo}}</span>
          </div>
          <div class="xianmu_A">
            <span>项目：</span>
            <span>{{item.itemName}}</span>
          </div>
          <div class="xianmu_b">
            <span>配件：</span>
            <span>{{item.partName}}</span>
          </div>
        </div>
        <div class="jiesuan" v-if="item.settlementStatus != 1" @click="jiesuan(item)">
          <span class="jies">结算</span>
        </div>
        <div class="shijian" v-else>
          <span class="left">结算时间：</span>
          <span class="right" style="color: #666;">{{item.settlementDate}}</span>
        </div>
      </div>-->
      <div class="mian" v-for="(item,idx) in list" :key="idx">
        <div class="dian">
          <span class="left bianhao">编号：{{item.number}}</span>
          <span class="right" v-if="item.status == 0">待审核</span>
          <span class="right" v-if="item.status == 1">待到店</span>
          <span class="right" v-if="item.status == 2">已到店</span>
          <span class="right" v-if="item.status == 3">逾期未到店</span>
          <span class="right" v-if="item.status == 4">已取消</span>
        </div>
        <div class="xianmu">
          <div class="xianmu_A">
            <span>车牌号码：</span>
            <span>{{item.carNo}}</span>
          </div>
          <div class="xianmu_A">
            <span>门店名称：</span>
            <span>{{item.garageName}}</span>
          </div>
          <div class="xianmu_A">
            <span>预约时间：</span>
            <span>{{item.visitDate}}</span>
          </div>
          <div class="xianmu_A">
            <span>预约类型：</span>
            <span>{{item.type == 1?'线下预约':'付款订单'}}-{{item.items}}</span>
          </div>
          <div class="xianmu_A">
            <span>预约内容：</span>
            <span v-if="item.type == 1">{{item.remark}}</span>
            <span v-if="item.type == 0" style="color: #483D8B;" @click="chakan(item)">查看订单</span>
          </div>
        </div>
        <div class="shijian">
          <span
            class="right xiangqin"
            style="color: #666;"
            v-if="item.status != 4"
            @click="toxiangqin(item)"
          >预约详情</span>
        </div>
      </div>

      <!-- <van-empty v-if="list.length < 1" description="暂无数据~" /> -->
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: "0"
    };
  },
  methods: {
    jiesuan(item) {
      this.$router.push({
        path: "ka_pay",
        query: {
          isok: false
        }
      });
      localStorage.setItem("xiaofei", JSON.stringify(item));
    },
    chakan(item) {
      window.location.href =
        "/order_details?id=" +
        item.id +
        "&number=" +
        item.number +
        "&type=" +
        item.type +
        "&yyisok=" +
        "true" +
        "&isok=" +
        "true";
    },
    toxiangqin(item) {
      this.$router.push({
        path: "make_xiangqing",
        query: {
          id: item.id,
          type: item.type,
          appointmentId: item.appointmentId
        }
      });
    },
    toyuyue() {
      this.$router.push({
        path: "make_list_details",
        query: {}
      });
    },
    onLoad() {
      const data = {
        // gid: 1
        gid: this.shangjia.id
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .yuyuelist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    pushHistory() {
      //写入空白历史路径
      let state = {
        title: "title",
        url: "#"
      };
      window.history.pushState(state, state.title, state.url);
    },

    backziding() {
      this.pushHistory();
      window.addEventListener(
        "popstate",
        function(e) {
          location.href = "/mine";
        },
        false
      );
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "mine"
      // });

    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.mian {
  margin: 12px 12px;
  background: #fff;
  padding-bottom: 10px;
  overflow: hidden;
}
.dian {
  padding: 0 12px;
  overflow: hidden;
  font-size: 14px;
  line-height: 35px;
  color: #000;
}
.chepaih {
  display: inline-block;
  background: #000093;
  color: #fff;
  padding: 0 2px;
}
.bianhao {
  color: #666;
}
.xianmu {
  padding: 0 12px;
  overflow: hidden;
  font-size: 13px;
  color: #000;
}
.xiangqin {
  border-radius: 5px;
  border: 2px solid #666;
  padding: 0 5px;
}
.xianmu_A {
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jiesuan {
  line-height: 25px;
  color: red;
  font-size: 15px;
  text-align: center;
}
.liusui {
  font-size: 13px;
  color: rgb(1, 177, 133);
}
.jies {
  display: inline-block;
  border: 2px solid red;

  padding: 0 35px;
}
.shijian {
  font-size: 14px;
  padding: 0 12px;
}
.hedsding {
  padding-top: 46px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ddccd {
  color: rgb(1, 177, 133) !important;
}
</style>